<template>
  <div class="kyc">
    <AppTopbar title="KYC Documents" hideSearchNotif></AppTopbar>
    <KYCDocuments />
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import KYCDocuments from "@/components/KYCDocuments.vue";

export default {
  name: "KnowYourCustomer",
  components: {
    AppTopbar,
    KYCDocuments,
  },
};
</script>

<style></style>
