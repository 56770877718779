<template>
	<div class="container-fluid pt-50 pt-md-80 pb-80 pb-md-0">
		<div class="card card__kyc mt-24 mb-20">
			<b-form @submit.prevent="kycdocSubmit">
      <div class="row pt-20 pt-md-40">
        <div class="col-md-6">
        	<div class="mw528">
        		<span class="d-block fs18 lh28 fw500 mb-14"> AADHAAR Verification </span>

        		<div class="row">
        			<div class="col">
        				<b-form-group
        				id="company-gstin-field"
        				label-for="company-gstin"
        				>
        				<b-form-input
        				required
        				type="text"
        				pattern="[0-9]{12}"
        				oninvalid="this.setCustomValidity('Enter valid AADHAAR number.')"
        				oninput="this.setCustomValidity('')"
        				:formatter="numbers"
        				maxlength="12"
        				minlength="12"
        				id="company-gstin"
        				v-model="form.adhaar"
        				placeholder="AADHAAR Number(required)"
        				></b-form-input>
        				<div class="input-group-append">
        					<div class="input-group-text">
        						<b-button size="xxs" @click="verifyAadhaar(form.adhaar)" variant="tertiary"> Verify </b-button>
        						<!-- <b-spinner></b-spinner> -->
        					</div>
        				</div>
        			</b-form-group>
        		</div>
        	</div>

              <div class="row col" v-if="noLinked">
                <!-- <div class="row col" > -->
                      <div class="col-md-4 pt-8">
                        <b-form-group id="otp-field" label-for="otp">
                          <b-form-input
                            type="text"
                            id="otp"
                            v-model="otp"
                            placeholder="- Enter OTP -"
                            autofocus
                            required
                            :formatter="maxsix"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-4 col-12 pt-5">
                        <b-button variant="primary" @click="verifyOtp(otp)" size="lg" class="mr-16"> {{ btns.verify }} </b-button>
                      </div>
                      <div class="col col-md-12 pt-8">
                        <b-button variant="primary" @click="resendOtp(form.adhaar)" :disabled="!resend" size="lg" class="mr-16"> Resend OTP </b-button>
                        <b-link @click="showUpload">Manually Upload Aadhaar Images</b-link>
                      </div>
                  </div>
			</div>
			</div>

			<div class="col-md-6 pt-90" v-if="noNotLinked">
				<!-- <div class="col-md-6 pt-90" > -->
				<div class="row">
                  	<div class="col-md-3 pb-md-0 pb-32">
                  		<div
                  		class="rounded border text-center d-inline-block w-sm-100"
                  		>
                  		<img
                  		v-if="form.adhaarfrontImg != null && form.adhaarbackImg != null "
                  		src="@/assets/svg/document-uploaded.svg"
                  		class="p-44"
                  		/>
                  		<img v-else src="@/assets/svg/document.svg" class="p-44" />
                  	</div>
                  </div>
                  <div class="col-md-7 ml-20 d-flex flex-column justify-content-center">
                  	<span class="d-block fs16 lh19 mb-10 fw500">
                  		Manual Aadhaar Verification
                  	</span>
                  	<span class="d-block fs14 lh20 mb-18 text-gray-600">
                  		<p><strong>Front and Back Side of Seller Aadhaar Card.</strong></p>
                  		Max file size per document is 1.5MB. Supported formats are
                  		JPG, PNG, JPEG.
                  	</span>
                  </div>
                  <div class="col row pt-20">
                  	<div class="col-md-6">
                  		<b-form-file
                  		plain
                  		accept=".jpeg, .jpg, .png"
                  		ref="aadhaar-front-input"
                  		v-model="form.adhaarfrontImg"
                  		class="d-none"
                  		@input="selectImages(form.adhaarfrontImg,'front')"
                  		></b-form-file>
                  		<div class="w-sm-100">
                  			<b-button
                  			@click="popInput('aadhaar-front-input')"
                  			variant="primary"
                  			class="w-sm-100 mr-0 mr-md-8"
                  			>
                  			<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
                  			Select Front Side
                  		</b-button>
                  		<b-button
                  		v-if="form.adhaarfrontImg != null"
                  		@click="clearImages('front')"
                  		variant="red"
                  		class="w-sm-100 mt-8 mt-md-0"
                  		>
                  		<i class="icon-close-variant"></i>
                  	</b-button>
                  </div>
                  <div class="col mt-10" v-if="form.adhaarfrontImg != null">
                  	{{form.adhaarfrontImg ? form.adhaarfrontImg.name :''}} Selected
                  </div>
              </div>
              <div class="col-md-6">
              	<b-form-file
              	plain
              	accept=".jpeg, .jpg, .png"
              	ref="aadhaar-back-input"
              	v-model="form.adhaarbackImg"
              	class="d-none"
              	@input="selectImages(form.adhaarbackImg,'back')"
              	></b-form-file>
              	<div class="w-sm-100">
              		<b-button
              		@click="popInput('aadhaar-back-input')"
              		variant="primary"
              		class="w-sm-100 mr-0 mr-md-8"
              		>
              		<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
              		Select Back Side
              	</b-button>
              	<b-button
              	v-if="form.adhaarbackImg != null"
              	@click="clearImages('back')"
              	variant="red"
              	class="w-sm-100 mt-8 mt-md-0"
              	>
              	<i class="icon-close-variant"></i>
              </b-button>
	          </div>
		          <div class="col mt-10" v-if="form.adhaarbackImg != null">
		          	{{form.adhaarbackImg ? form.adhaarbackImg.name :''}} Selected
		          </div>
		      </div>
		  </div>
			  <!-- <div class="col-md-12 pt-20 d-flex flex-column justify-content-center">
	                <span class="d-block fs16 lh19 mb-10 fw500">
	                  AADHAAR Proof
	                </span>
	                <span class="d-block fs14 lh20 mb-18 text-gray-600">
	                  <p><strong>Front and Back Side of Seller Aadhaar Card.</strong></p>
	                  Max file size per document is 1.5MB. Supported formats are
	                  JPG, PNG, JPEG.
	                </span>
	              </div> -->
			</div>
			</div>	
          
  </div>
  <div class="row pt-20 pt-md-40">
          <div class="col-md-6">
            <div class="mw528">
              <span class="d-block fs18 lh28 fw500 mb-14"> GSTIN </span>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="company-gstin-field"
                    label-for="company-gstin"
                  >
                    <b-form-input
                      :required="kycInfo.sellerCompanyType == 6 ? false : true"
                      type="text"
                      maxlength="15"
                      id="company-gstin"
                      @input="enterGST(form.gstno)"
                      v-model="form.gstno"
                      :placeholder="kycInfo.sellerCompanyType == 6 ? `Company's GSTIN(optional)` : `Company's GSTIN(required)` "
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mw528">
              <span class="d-block fs18 lh28 fw500 mb-14"> PAN </span>
              <div class="row">
                <div class="col">
                  <b-form-group
                    id="company-pan-field"
                    label-for="company-pan"
                  >
                    <b-form-input
                      required
                      maxlength="10"
                      id="company-pan"
                      type="text"
                      v-model="form.panNo"
                      placeholder="Company's PAN(required)"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          
      </div>
      <div class="row pt-20 pt-md-40 pb-20">
  		  <div class="col-md-6" v-if="hideCOI">
            <div class="mw528">
            	<span class="d-block fs18 lh28 fw500 mb-14"> Certificate of Incorporation </span>
              <div class="row">
                <div class="col">
                  <b-form-group
                    id="company-tan-field"
                    label=""
                    label-for="company-tan"
                  >
                    <b-form-input
                      required
                      id="company-tan"
                      placeholder="Company's CIN(required)"
                      v-model="form.coi"
                    ></b-form-input>
                  </b-form-group>
                </div>
            </div>
            </div>
        </div>
        <div class="col-md-6">
        	<div class="mw528">
        		<span class="d-block fs18 lh28 fw500 mb-14"> Photo Identification </span>
        		<div class="row" :class="{ 'pt-50' : hideCOI }">
        			<div class="col-md-3 pb-md-0 pb-32">
        				<div class="rounded border text-center d-inline-block w-sm-100">
	        				<img
	        				v-if="form.faceImg != null"
	        				src="@/assets/svg/document-uploaded.svg"
	        				class="p-44"
	        				/>
	        				<img v-else src="@/assets/svg/document.svg" class="p-44" />
        				</div>
        			</div>
        			<div class="col-md-8 ml-30 d-flex flex-column justify-content-center">
	                  	<span class="d-block fs14 lh20 pt-10 text-gray-600">
	        				<p><strong>Take a Photo or Select from your Device's Storage</strong></p>
	        				Max file size per document is 1.5MB. Supported formats are
	        				JPG, PNG, JPEG.
	        			</span>
                  	</div>
        		<div class="row col pt-20">
        			<b-form-file
        			plain
        			accept=".jpeg, .jpg, .png"
        			ref="face-file-input"
        			v-model="form.faceImg"
        			class="d-none"
        			@input="selectImages(form.faceImg,'face')"
        			></b-form-file>
        				<div class="col-md-4">
        					<b-button
	        				@click="openWebcamModal"
	        				variant="primary"
	        				class="w-sm-100 mr-0 mr-md-8"
	        				>
	        				<img class="mr-5 selectImage" src="@/assets/svg/camera.svg">
	        				Take Photo
	        			</b-button>
        				</div>
        				<div class="col-md-2 mt-5 fs20"><strong>OR</strong></div>
	        			<div class="col-md-6">
	        				<b-button
			        			@click="popInput('face-file-input')"
			        			variant="primary"
			        			class="w-sm-100 mr-0 mr-md-8"
			        			>
			        			<img class="mr-5 selectImage" src="@/assets/svg/image.svg">
			        			Select Photo
				        		</b-button>
				        		<b-button
				        		v-if="form.faceImg != null"
				        		@click="clearImages('face')"
				        		variant="red"
				        		class="w-sm-100 mt-8 mt-md-0"
				        		>
				        		<i class="icon-close-variant"></i>
				        	</b-button>
				        	<div class="col row pt-10" v-if="form.faceImg">
			        			{{form.faceImg ? form.faceImg.name : ''}} File Uploaded
			        		</div>
	        			</div>	
    </div>
		</div>
		</div>
		</div>
      </div>
  <div class="row pt-10">
  	<div class="col-md-6">
  		<div class="pt-22">
  			<b-form-group
  			id="signing-name-field"
  			label="Signing Name (required)"
  			label-for="signing-name"
  			>
  			<b-form-input
  			required
  			id="signing-name"
  			v-model="form.signingName"
  			placeholder="Your name"
  			></b-form-input>
  		</b-form-group>
  	</div>
  </div>
</div>

<div class="row pt-10 pb-20 ml-0 fs16">
	<strong><span>By clicking Submit, you agree to Shyplite's Agreement, 
		<b-link target="_blank" href="https://shyplite.com/tou" class="ml-0 fs16">Terms of Service</b-link> and 
		<b-link class="ml-0 fs16" target="_blank" href="https://shyplite.com/privacy">Privacy Policy</b-link></span></strong>
	</div>
	<div class="d-flex pb-20">
		<b-button
		variant="primary"
		size="lg"
		type="submit"
		>
		Submit
	</b-button>
	<b-button to="/dashboard" variant="secondary" size="lg" class="ml-16">
		Skip
	</b-button>
</div>
</b-form>
</div>
<KYCInfoModal ref="infoModal" />
<Webcam @openModal="openWebcamModal" ref="webcam" />
</div>
</template>

<script>
import KYCInfoModal from "@/components/KYCInfoModal.vue";
import Webcam from "@/components/Webcam.vue";
import { mapState } from "vuex";
export default {
	name: "KYCDocuments",
	data() {
		return {
			files: [],
			src: "",
      noLinked:false,
      noNotLinked:false,
			idImg: "",
			addressImg: "",
			coiImg: "",
			gstImg: "",
			value: "",
      otp:null,
			adhaarImg:"",
			faceImg:"",
			form: {
				gstno: '',
				adhaar:'',
				adhaarfrontImg:null,
				adhaarbackImg:null,
				faceImg:null
			},
			btns:{
				verify:'Verify OTP'
			},
			errorFlag1:false,
			errorFlag2:false,
			errorFlag4:false,
			errorFlag5:false,
      resend:false,
			errorText:"",
			companyTypeList:["","Private Limited Company","Sole Proprietorship/Firm","One Person Company (OPC Pvt. Ltd.)","Partnership Firm","Limited Liability Partnership (LLP)","Individual","Hindu Undivided Family (HUF)","Public Limited Company","Others"],
			hideCOI:false,
			uploadImages:[],
			uploadfaceImages:[]
		};
	},
	components: {
		KYCInfoModal,
		Webcam
	},
	watch: {
		idImg: function (newVal) {
			this.form.idImg = newVal;
		},
		addressImg: function (newVal) {
			this.form.addressImg = newVal;
		},
		gstImg: function (newVal) {
			this.form.gstImg = newVal;
		},
		coiImg: function (newVal) {
			this.form.coiImg = newVal;
		}
	},
	methods: {
    async resendOtp(adhaar) {
      try {
		this.resend = false;
        const res = await this.axios.post('/register/aadhaarResendOTP',{adhaarNumber:adhaar})
        if (res.data.status) {
          this.popToast('booked','Booked',res.data.message)
			setTimeout(() => {
				this.resend = true
				this.otp = ''
			},30000)

        } else {
          this.popToast('failed','Failed',res.data.message)
        }
      } catch(e) {
        // statements
        console.log(e);
      }
    },
    async verifyOtp(Otp){
      console.log(typeof(Otp))
      try {
        const res = await this.axios.post('/register/aadhaarVerifyOTP',{otp:Otp})
        if (res.data.status) {
          this.popToast('booked','Booked',res.data.message)
          this.noLinked = false
        } else {
          this.noLinked = true
          this.popToast('failed','Failed',res.data.message)
        }
      } catch(e) {
        // statements
        console.log(e);
      }
    },
    showUpload() {
      this.noLinked = !this.noLinked
      this.noNotLinked = !this.noNotLinked
    },
    async verifyAadhaar(aadhaar) {
      try {
        const res = await this.axios.post('/register/aadhaarValidation',{adhaarNumber:aadhaar})
        console.log(res)
        if (res.data.status) {
          if (res.data.isPhone) {
            this.noLinked = true
            this.popToast('booked','Booked',res.data.message)
            setTimeout(() => {
              this.resend = true
              this.otp = ''
            },30000)
          }
	      else {
            this.noNotLinked = true
            this.popToast('booked','Booked','We could not find a mobile number registered with your Aadhaar. Please upload images of the front and back side of the Aadhaar.')
          }
        }
	    else {
          this.popToast('booked','Booked',res.data.message);
    		  if (res.data.isValid) {
    			  this.noNotLinked = true
    		  }
            }
          }
	  catch(e) {
        // statements
        console.log(e);
      }
    },
		openWebcamModal(){
			this.$refs.webcam.isOpen = !this.$refs
			.webcam.isOpen;
		},
		clearImages(type){
			if (type == 'front') {
				this.form.adhaarfrontImg = null
				return
			} else if (type == 'back') {
				this.form.adhaarbackImg = null
				return
			} else {
				this.form.faceImg = null
			}
		},
		selectImages(image,type) {
			if(image != null) {
				if (type == 'front') {
					if (!(image.name.split('.').pop().includes('png') || image.name.split('.').pop().includes('jpeg') || image.name.split('.').pop().includes('jpg'))) {
						this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
						this.form.adhaarfrontImg = null
						return
					} else {
					}
				} else if(type == 'back') {
					if (!(image.name.split('.').pop().includes('png') || image.name.split('.').pop().includes('jpeg') || image.name.split('.').pop().includes('jpg'))) {
						this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
						this.form.adhaarbackImg = null
						return
					} else {
					}
				} else {
					if (!(image.name.split('.').pop().includes('png') || image.name.split('.').pop().includes('jpeg') || image.name.split('.').pop().includes('jpg'))) {
						this.popToast('failed','Failed','Only PNG, JPG or JPEG file format is supported')
						this.form.faceImg = null
						return
					} else {
					}
				}
			}
			if (this.form.adhaarfrontImg && this.form.adhaarbackImg && this.form.adhaarfrontImg.name == this.form.adhaarbackImg.name && type == 'back') {
		        this.popToast('failed','Failed',`Aadhaar's Front and Back cannot be same. Please Upload an Appropriate Image.`)
		        this.form.adhaarbackImg = null
		      }
      if (this.form.adhaarfrontImg && this.form.adhaarbackImg && this.form.adhaarbackImg.name == this.form.adhaarfrontImg.name && type == 'front') {
		        this.popToast('failed','Failed',`Aadhaar's Front and Back cannot be same. Please Upload an Appropriate Image.`)
		        this.form.adhaarfrontImg = null
		      }
		},
		enterGST(value){
			if (value.length > 0) {
				this.form.panNo = String(value).substring(2, 12);
			}
		},
		async kycdocSubmit(event) {
			if (this.form.idImg == null) {
				this.errorFlag1 = true
				this.errorText = "Identification Proof is Mandatory"
				this.$router.push('#errorDiv')
				this.$router.push('')
			}
			else if(this.form.addressImg == null) {
				this.errorFlag2 = true
				this.errorText = "Address Proof is Mandatory "
				this.$router.push('#errorDiv')
				this.$router.push('')
			}
			else if(this.form.gstImg == null && this.kycInfo.sellerCompanyType != 6) {
				this.errorFlag4 = true
				this.errorText = "GSTIN Proof is Mandatory "
				this.$router.push('#errorDiv')
				this.$router.push('')
			}
			else if(this.form.coiImg == null && this.hideCOI) {
				this.errorFlag5 = true
				this.errorText = "COI Proof is Mandatory "
			}
			else {
				event.preventDefault();
				const userData = { ...this.form, ...this.kycInfo };
				let fd = new FormData();
				for (const key of Object.keys(userData)) {
					fd.append(key, userData[key]);
				}

				try {
					this.openInfoModal();
					const response = await this.axios.post("/register/company", fd);
					if (response.data.success == true) {
						setTimeout(() => {
							this.$router.push("/dashboard");
							this.$store.commit('updateKycData',{})
						},2000)
					} else {
						this.popToast(
							"failed",
							"Form Submission Failed",
							response.data.result.message
							);
					}
				} catch (err) {
					console.log(err);
				}
			}
			
		},
		popInput(value) {
			this.$refs[value].$el.click();
		},
		clearIdFile(value) {
			this.$refs[value].reset();
			this.url = null;
		},
		openInfoModal: function () {
			this.$refs.infoModal.isOpen = !this.$refs.infoModal.isOpen;
			this.bottomOrderMenuToggle = true;
		},
	},
	mounted() {
		this.value = ""
		if(this.kycInfo.sellerCompanyType == 1 || this.kycInfo.sellerCompanyType == 3 || this.kycInfo.sellerCompanyType == 5 || this.kycInfo.sellerCompanyType == 8) {
			this.hideCOI = true
		} else {
			this.hideCOI = false
		}
	},
	computed: {
		...mapState(["kycInfo"]),
		...mapState(["user"])
	},
};
</script>


<style scoped>
/deep/ a:hover, a:focus, a:active {
	color: #006eff !important;
}
.selectImage {
	width: 1.5rem;
	height: 1.5rem;
}
.html {
	scroll-behavior: smooth !important;
}
.errorText{
	height: 2.5rem;
	width: 16rem;
	background-color: #ffa6a6;
	color: red;
	border: 1px #ffa6a6;
	border-radius: 5px;
}

.errorText span {
	margin: 0px;
	position: relative;
	top: 30%;
	left: 8%;   
	transform: translate(0,-50%);
	text-align: center;
}

.input-group-addon {
	overflow: auto;
	border: 1px solid rgb(207, 207, 207);
	padding: 10px;
	margin-top: 0px;
	max-height: 13rem;
}
.input-group-text {
	height: 2.75rem !important;
}
</style>