var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid pt-50 pt-md-80 pb-80 pb-md-0" },
    [
      _c(
        "div",
        { staticClass: "card card__kyc mt-24 mb-20" },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.kycdocSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "row pt-20 pt-md-40" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" AADHAAR Verification ")]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-gstin-field",
                                "label-for": "company-gstin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  type: "text",
                                  pattern: "[0-9]{12}",
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid AADHAAR number.')",
                                  oninput: "this.setCustomValidity('')",
                                  formatter: _vm.numbers,
                                  maxlength: "12",
                                  minlength: "12",
                                  id: "company-gstin",
                                  placeholder: "AADHAAR Number(required)"
                                },
                                model: {
                                  value: _vm.form.adhaar,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "adhaar", $$v)
                                  },
                                  expression: "form.adhaar"
                                }
                              }),
                              _c("div", { staticClass: "input-group-append" }, [
                                _c(
                                  "div",
                                  { staticClass: "input-group-text" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          size: "xxs",
                                          variant: "tertiary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.verifyAadhaar(
                                              _vm.form.adhaar
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Verify ")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.noLinked
                      ? _c("div", { staticClass: "row col" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-4 pt-8" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { id: "otp-field", "label-for": "otp" }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      type: "text",
                                      id: "otp",
                                      placeholder: "- Enter OTP -",
                                      autofocus: "",
                                      required: "",
                                      formatter: _vm.maxsix
                                    },
                                    model: {
                                      value: _vm.otp,
                                      callback: function($$v) {
                                        _vm.otp = $$v
                                      },
                                      expression: "otp"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-4 col-12 pt-5" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-16",
                                  attrs: { variant: "primary", size: "lg" },
                                  on: {
                                    click: function($event) {
                                      return _vm.verifyOtp(_vm.otp)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.btns.verify) + " ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col col-md-12 pt-8" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-16",
                                  attrs: {
                                    variant: "primary",
                                    disabled: !_vm.resend,
                                    size: "lg"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.resendOtp(_vm.form.adhaar)
                                    }
                                  }
                                },
                                [_vm._v(" Resend OTP ")]
                              ),
                              _c("b-link", { on: { click: _vm.showUpload } }, [
                                _vm._v("Manually Upload Aadhaar Images")
                              ])
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm.noNotLinked
                  ? _c("div", { staticClass: "col-md-6 pt-90" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-3 pb-md-0 pb-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded border text-center d-inline-block w-sm-100"
                            },
                            [
                              _vm.form.adhaarfrontImg != null &&
                              _vm.form.adhaarbackImg != null
                                ? _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document-uploaded.svg")
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document.svg")
                                    }
                                  })
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-7 ml-20 d-flex flex-column justify-content-center"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "d-block fs16 lh19 mb-10 fw500" },
                              [_vm._v(" Manual Aadhaar Verification ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-block fs14 lh20 mb-18 text-gray-600"
                              },
                              [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "Front and Back Side of Seller Aadhaar Card."
                                    )
                                  ])
                                ]),
                                _vm._v(
                                  " Max file size per document is 1.5MB. Supported formats are JPG, PNG, JPEG. "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col row pt-20" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("b-form-file", {
                                ref: "aadhaar-front-input",
                                staticClass: "d-none",
                                attrs: {
                                  plain: "",
                                  accept: ".jpeg, .jpg, .png"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.selectImages(
                                      _vm.form.adhaarfrontImg,
                                      "front"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.adhaarfrontImg,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "adhaarfrontImg", $$v)
                                  },
                                  expression: "form.adhaarfrontImg"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "w-sm-100" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-sm-100 mr-0 mr-md-8",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.popInput(
                                            "aadhaar-front-input"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-5 selectImage",
                                        attrs: {
                                          src: require("@/assets/svg/image.svg")
                                        }
                                      }),
                                      _vm._v(" Select Front Side ")
                                    ]
                                  ),
                                  _vm.form.adhaarfrontImg != null
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "w-sm-100 mt-8 mt-md-0",
                                          attrs: { variant: "red" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clearImages("front")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-close-variant"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.form.adhaarfrontImg != null
                                ? _c("div", { staticClass: "col mt-10" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.adhaarfrontImg
                                            ? _vm.form.adhaarfrontImg.name
                                            : ""
                                        ) +
                                        " Selected "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c("b-form-file", {
                                ref: "aadhaar-back-input",
                                staticClass: "d-none",
                                attrs: {
                                  plain: "",
                                  accept: ".jpeg, .jpg, .png"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.selectImages(
                                      _vm.form.adhaarbackImg,
                                      "back"
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.adhaarbackImg,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "adhaarbackImg", $$v)
                                  },
                                  expression: "form.adhaarbackImg"
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "w-sm-100" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "w-sm-100 mr-0 mr-md-8",
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.popInput(
                                            "aadhaar-back-input"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "mr-5 selectImage",
                                        attrs: {
                                          src: require("@/assets/svg/image.svg")
                                        }
                                      }),
                                      _vm._v(" Select Back Side ")
                                    ]
                                  ),
                                  _vm.form.adhaarbackImg != null
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "w-sm-100 mt-8 mt-md-0",
                                          attrs: { variant: "red" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clearImages("back")
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-close-variant"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.form.adhaarbackImg != null
                                ? _c("div", { staticClass: "col mt-10" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.form.adhaarbackImg
                                            ? _vm.form.adhaarbackImg.name
                                            : ""
                                        ) +
                                        " Selected "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "row pt-20 pt-md-40" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" GSTIN ")]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-gstin-field",
                                "label-for": "company-gstin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required:
                                    _vm.kycInfo.sellerCompanyType == 6
                                      ? false
                                      : true,
                                  type: "text",
                                  maxlength: "15",
                                  id: "company-gstin",
                                  placeholder:
                                    _vm.kycInfo.sellerCompanyType == 6
                                      ? "Company's GSTIN(optional)"
                                      : "Company's GSTIN(required)"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.enterGST(_vm.form.gstno)
                                  }
                                },
                                model: {
                                  value: _vm.form.gstno,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "gstno", $$v)
                                  },
                                  expression: "form.gstno"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" PAN ")]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "company-pan-field",
                                "label-for": "company-pan"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  required: "",
                                  maxlength: "10",
                                  id: "company-pan",
                                  type: "text",
                                  placeholder: "Company's PAN(required)"
                                },
                                model: {
                                  value: _vm.form.panNo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "panNo", $$v)
                                  },
                                  expression: "form.panNo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row pt-20 pt-md-40 pb-20" }, [
                _vm.hideCOI
                  ? _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "mw528" }, [
                        _c(
                          "span",
                          { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                          [_vm._v(" Certificate of Incorporation ")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "company-tan-field",
                                    label: "",
                                    "label-for": "company-tan"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      required: "",
                                      id: "company-tan",
                                      placeholder: "Company's CIN(required)"
                                    },
                                    model: {
                                      value: _vm.form.coi,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "coi", $$v)
                                      },
                                      expression: "form.coi"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "mw528" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" Photo Identification ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "row", class: { "pt-50": _vm.hideCOI } },
                      [
                        _c("div", { staticClass: "col-md-3 pb-md-0 pb-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded border text-center d-inline-block w-sm-100"
                            },
                            [
                              _vm.form.faceImg != null
                                ? _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document-uploaded.svg")
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document.svg")
                                    }
                                  })
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-8 ml-30 d-flex flex-column justify-content-center"
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-block fs14 lh20 pt-10 text-gray-600"
                              },
                              [
                                _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      "Take a Photo or Select from your Device's Storage"
                                    )
                                  ])
                                ]),
                                _vm._v(
                                  " Max file size per document is 1.5MB. Supported formats are JPG, PNG, JPEG. "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "row col pt-20" },
                          [
                            _c("b-form-file", {
                              ref: "face-file-input",
                              staticClass: "d-none",
                              attrs: { plain: "", accept: ".jpeg, .jpg, .png" },
                              on: {
                                input: function($event) {
                                  return _vm.selectImages(
                                    _vm.form.faceImg,
                                    "face"
                                  )
                                }
                              },
                              model: {
                                value: _vm.form.faceImg,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "faceImg", $$v)
                                },
                                expression: "form.faceImg"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "col-md-4" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-sm-100 mr-0 mr-md-8",
                                    attrs: { variant: "primary" },
                                    on: { click: _vm.openWebcamModal }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-5 selectImage",
                                      attrs: {
                                        src: require("@/assets/svg/camera.svg")
                                      }
                                    }),
                                    _vm._v(" Take Photo ")
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col-md-2 mt-5 fs20" }, [
                              _c("strong", [_vm._v("OR")])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-md-6" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-sm-100 mr-0 mr-md-8",
                                    attrs: { variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.popInput("face-file-input")
                                      }
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-5 selectImage",
                                      attrs: {
                                        src: require("@/assets/svg/image.svg")
                                      }
                                    }),
                                    _vm._v(" Select Photo ")
                                  ]
                                ),
                                _vm.form.faceImg != null
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "w-sm-100 mt-8 mt-md-0",
                                        attrs: { variant: "red" },
                                        on: {
                                          click: function($event) {
                                            return _vm.clearImages("face")
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-close-variant"
                                        })
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.form.faceImg
                                  ? _c(
                                      "div",
                                      { staticClass: "col row pt-10" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.faceImg
                                                ? _vm.form.faceImg.name
                                                : ""
                                            ) +
                                            " File Uploaded "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "row pt-10" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c(
                    "div",
                    { staticClass: "pt-22" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "signing-name-field",
                            label: "Signing Name (required)",
                            "label-for": "signing-name"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              required: "",
                              id: "signing-name",
                              placeholder: "Your name"
                            },
                            model: {
                              value: _vm.form.signingName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "signingName", $$v)
                              },
                              expression: "form.signingName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "row pt-10 pb-20 ml-0 fs16" }, [
                _c("strong", [
                  _c(
                    "span",
                    [
                      _vm._v(
                        "By clicking Submit, you agree to Shyplite's Agreement, "
                      ),
                      _c(
                        "b-link",
                        {
                          staticClass: "ml-0 fs16",
                          attrs: {
                            target: "_blank",
                            href: "https://shyplite.com/tou"
                          }
                        },
                        [_vm._v("Terms of Service")]
                      ),
                      _vm._v(" and "),
                      _c(
                        "b-link",
                        {
                          staticClass: "ml-0 fs16",
                          attrs: {
                            target: "_blank",
                            href: "https://shyplite.com/privacy"
                          }
                        },
                        [_vm._v("Privacy Policy")]
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "d-flex pb-20" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary", size: "lg", type: "submit" }
                    },
                    [_vm._v(" Submit ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-16",
                      attrs: {
                        to: "/dashboard",
                        variant: "secondary",
                        size: "lg"
                      }
                    },
                    [_vm._v(" Skip ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c("KYCInfoModal", { ref: "infoModal" }),
      _c("Webcam", { ref: "webcam", on: { openModal: _vm.openWebcamModal } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }